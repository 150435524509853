/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap'); */

/* Ensure no horizontal overflow and control the layout */

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
    width: 100%;
    min-height: 100vh;
    /* Ensure borders/padding don't affect layout width */
    display: flex;
    flex-direction: column;
}

/* Fix for navigation and content */
nav {
    display: flex;
    padding: 2.5rem;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: lightpink;
    z-index: 50;
    /* Ensures it's on top of other content */
    width: 100%;
    /* Ensure it doesn't overflow */
    /* Include padding/border in the element width */
}

/* Navbar Links container */
.navbar-links-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2.8125rem;
    flex-wrap: wrap;
    /* Ensure it wraps on smaller screens */
}

/* Ensure logo doesn't overflow */
.nav-logo-container {
    width: 5.19606rem;
    height: var(--40, 2.5rem);
    flex-shrink: 0;
}

/* Make sure content doesn't overflow */
.container {
    max-width: 100%;
    /* Prevent horizontal overflow */
    overflow-x: hidden;
}

.home-img:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.scroll-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-left: 30px;
}

.footer img {
    height: auto;
    max-width: 100%;
}

.image-container {
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.image-container img {
    height: auto;
    width: 100%;
}

.scroll-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2px;
}

.scroll-text {
    /* font-family: Lato; */
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
}

.primary-button {
    border-radius: 0.625rem;
    background: var(--Lillup---Red, #ec1f24);
    color: #ffffff;
    display: flex;
    height: 2.4375rem;
    padding: 1.0625rem 1.875rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
}

/* conact section */

.contact-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 400px;
}

.contact-page-primary-text {
    color: var(--Lillup---Black, #2d2e2e);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footer-background {
    background-image: url('../src/assets/images/image3.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

.hover-scale {
    transition: transform 0.5s ease-in-out;
}

.hover-scale:hover {
    transform: scale(1.1);
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
    .navbar-links-container {
        flex-direction: column;
        gap: 1rem;
    }

    .home-image-section {
        flex-direction: column;
    }
}

@media (min-width: 640px) {
    .primary-heading {
        font-size: 4rem;
    }
}

@media (min-width: 768px) {
    .logo {
        width: 100%;
        height: auto;
    }

    .primary-heading {
        font-size: 50px;
        /* font-family: 'lato'; */
        font-weight: 600;
    }

    img,
    video {
        height: auto;
    }

    .home-img {
        position: unset;
        transform: none;
        width: 648.19px;
        height: 500px;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }

    .home-banner-container {
        display: flex;
        flex-direction: row;
    }

    .home-image-section {
        margin: 0;
    }

    .home-text-section {
        position: relative;
    }

    .scroll-container {
        align-items: center;
    }
}

@media (min-width: 1024px) {
    .home-banner-container {
        padding: 5rem 2.5rem 0 9rem;
        left: 0;
        margin-left: 0;
        justify-content: space-between;
        position: relative;
    }

    .primary-text {
        font-size: 32px;
    }

    .home-image-section {
        display: flex;
        flex: 1 1;
        max-width: 600px;
    }

    img,
    video {
        height: auto;
        max-width: 100%;
    }

    .home-text-section {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        /* gap: 3.75rem; */
        width: 35.0625rem;
    }
}
